<template>
  <div class="my-6 text-start">
    <h3 class="font-bold">Lista de {{ route.name }}</h3>
    <span class="text-sm text-gray-500">Aqui você pode observar todos os itens relacionados aos
      {{ route.name.toLowerCase() }} como suas respectivas ações.
    </span>
  </div>
  <div class="w-full">
    <div class="flex flex-row items-center">
      <div class="w-full p-2">
        <Input type="datalist" id="evento" name="evento" v-model="eventoId" label="Evento"
          placeholder="Selecione um evento" :data="eventos" @keyupSearch="getEvent" />
      </div>
      <div class="mt-3 justify-content-center">
        <Button color="primary" :text="`Buscar`" @click="getEvent" id="buscarProdutos"></Button>
      </div>
    </div>
  </div>
  <hr class="my-9" />
  <div class="card shadow-lg border rounded-lg p-8 bg-white mt-10">
    <div class="flex flex-col items-end">
      <div class="w-full p-2">
        <h3 class="font-bold">Informações de produtos</h3>
        <span class="text-sm text-gray-500">
          Aqui você pode observar todos os dados relacionados aos produtos do
          evento.
        </span>
      </div>
      <div class="flex flex-row gap-2 justify-center p-2">
        <router-link :to="`/produtos/criar`">
          <Button color="primary" :text="`Criar novo produto`" id="criarProduto"></Button>
        </router-link>
        <div>
          <Button color="primary" :text="`Configurar leitura produto`" id="configurarProdutos" @click="configurarProdutos"></Button>
        </div>
      </div>
    </div>
    <div class="card w-full p-2" v-if="data">
      <DataTable ref="dataTableRef" :headers="headers" :data="data" :options="options" color="primary" />
    </div>
  </div>
</template>

<script>
import DataTable from "../../components/Elements/Datatable.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, onMounted, ref } from "vue";
import { GET } from "../../services/api";
import { useRoute, useRouter } from "vue-router";
import Input from "@/components/Elements/Input.vue";
import { toDate } from "../../services/formater";
import { useStore } from "vuex";

export default {
  name: "Produtos",
  components: {
    DataTable,
    Button,
    Input,
  },
  data() {
    return {
      isMobile: false,
    };
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },

  setup() {
    const headers = [
      { key: "check", value: "check" },
      { key: "Id", value: "id" },
      { key: "Detalhes", value: "detalhes" },
      { key: "Sku", value: "cod_produto" },
      { key: "Preço", value: "valor", money: true },
      { key: "Quantidade", value: "qnt_por_produto" },
      { key: "Imagem", value: "imagem", photo: true },
      { key: "Ações", value: "" },
    ];

    const route = useRoute();
    const router = useRouter();
    const alert = inject("alert");
    const loader = inject("loading");
    const data = ref([]);
    const eventoId = ref("");
    const idEventoSemNome = ref("");
    const eventos = ref([]);
    const params = ref("");
    const dataTableRef = ref(null);
    const store = useStore();

    const getEvent = async () => {
      loader.open();

      try {
        const [eventoSelecionado] = eventos.value.filter(
          (evento) => evento.label == eventoId.value
        );

        eventoId.value = eventoSelecionado.value;

        localStorage.setItem("event_santo_cartao", eventoId.value);

        const temporario = await GET(`produto/${eventoId.value}/evento`);

        temporario.map((prod) => {
          prod.valor =
            prod.id_tipo_produto == 102 ? prod.valor_referencia : prod.valor;

          prod.imagem = `https://santocartao-files.s3.amazonaws.com/product-pictures/${prod.cod_produto}.jpg`;
        });
        data.value = temporario;

        if (data.value.length <= 0)
          alert.open(
            "Atenção!",
            `Não há produtos relacionados a esse evento!`,
            "warning"
          );

        eventoId.value = eventoSelecionado.label;
      } catch (e) {
        alert.open("Erro", "Um erro ocorreu, tente novamente", "danger");
      }
      loader.close();
    };

    const copyItem = (product) => {
      alert.open("Sucesso!", `Produto copiado com sucesso!`, "success");
      router.push(`/produtos/criar/${product.id}`);
    };

    const options = [
      { icon: "pen", path: "/produtos/editar", title: "Editar produto" },
      // { icon: "clone", path: '/produtos/criar_v2', action: (param) => copyItem(param), title: "Copiar produto" },
      { icon: "clone", path: "/produtos/criar", title: "Copiar produto" },
    ];

    const configurarProdutos = () => {

      if (dataTableRef.value.selectedItems.length === 0) {
        alert.open("Atenção!", "Selecione ao menos um produto para configurar", "warning");
        return;
      }

      store.commit('modalConfiguracao', {
        display: true,
        items: { items: dataTableRef.value.selectedItems, eventoId: idEventoSemNome.value },
        callbackOK: () => { '' }
      })

    }


    onMounted(async () => {
      loader.open();
      try {
        const response = await GET("event");
        response.map((evento) =>
          eventos.value.push({
            label: evento.nome + " - " + toDate(evento.data),
            value: evento.id,
          })
        );

        if (localStorage.getItem("event_santo_cartao") != "null") {
          const [eventoSelecionado] = eventos.value.filter(
            (evento) =>
              evento.value == localStorage.getItem("event_santo_cartao")
          );

          eventoId.value = eventoSelecionado.value;
          idEventoSemNome.value = eventoSelecionado.value;

          const temporario = await GET(`produto/${eventoId.value}/evento`);
          temporario.map((prod) => {
            prod.valor =
              prod.id_tipo_produto == 102 ? prod.valor_referencia : prod.valor;

            prod.imagem = `https://santocartao-files.s3.amazonaws.com/product-pictures/${prod.cod_produto}.jpg`;
          });
          data.value = temporario;

          if (data.value.length <= 0)
            alert.open(
              "Atenção!",
              `Não há produtos relacionados a esse evento!`,
              "warning"
            );

          eventoId.value = eventoSelecionado.label;
        }
      } catch (e) {
        alert.open("Erro", e, "danger");
      }

      if (route.params.id) {
        params.value = route.params.id;
        const [eventoParam] = eventos.value.filter(
          (evento) => evento.value == params.value
        );
        eventoId.value = eventoParam.label;

        try {
          const temporario = await GET(`produto/${eventoParam.value}/evento`);

          temporario.map((prod) => {
            prod.valor =
              prod.id_tipo_produto == 102 ? prod.valor_referencia : prod.valor;
          });
          data.value = temporario;
        } catch (e) {
          alert.open("Erro", e, "danger");
        }
      }
      loader.close();
    });


    return { headers, data, options, route, eventoId, getEvent, eventos, configurarProdutos, dataTableRef, };
  },
};
</script>

<style>
@media only screen and (max-width: 800px) {
  .responsive {
    float: left;
  }
}

.btn {
  width: 100%;
  align-items: center;
  justify-content: center;
}
</style>
